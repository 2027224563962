import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Joyride from "react-joyride";
import { getTourState, setTourState } from "utils/tourState";
import { TOUR_STATUS } from "constants";
import { STATUS } from "constants/fetchStatus";
import { steps as intialSteps } from "./data/steps";

const useTour = () => {
  const { count } = useSelector((state) => state.keys);
  const location = useLocation();
  const parseLocation = location.pathname.substring(1);
  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState([]);
  const [tourSection, setTourSection] = useState("");
  const [tourStatus, setTourStatus] = useState(false);

  const handleCallback = ({ status }) => {
    const finishedStatuses = [TOUR_STATUS.FINISHED, TOUR_STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setRun(false);
      setTourState(tourSection);
    }
  };

  useEffect(() => {
    const keysSections = ["home", "profile", "arbitration", "orders", "trade", "wallet"];
    const specialSections = ["arbitration", "orders", "trade", "wallet"];

    if (keysSections.includes(parseLocation)) {
      if (specialSections.includes(parseLocation)) {
        if (count > 1) {
          setTourSection(parseLocation);
        }
      } else {
        setTourSection(!count ? (getTourState("addExchange") ? "addExchange" : parseLocation) : parseLocation);
      }
    } else {
      setTourSection(parseLocation);
    }
  }, [parseLocation, count, setTourSection]);

  useEffect(() => {
    // Set initial steps
    setSteps(intialSteps[tourSection]);
    setTourStatus(getTourState(tourSection));
  }, [tourSection]);

  useEffect(() => {
    if (tourStatus) {
      setRun(tourStatus);
      document.body.style.overflow = "hidden"; // Disable scrolling when tour starts
    }

    return () => {
      document.body.style.overflow = "auto"; // Restore scrolling when component unmounts
    };
  }, [tourStatus]);

  const TourComponent = tourStatus && (
    <Joyride
      steps={steps}
      run={run}
      showSkipButton
      continuous
      scrollToFirstStep
      disableScrolling
      callback={handleCallback}
      styles={{
        options: {
          zIndex: 100000,
          primaryColor: "transparent",
        },
        overlay: {
          zIndex: 100000, // Ensure the overlay is on top
          position: "fixed",
        },
        spotlight: {
          zIndex: 100001, // Ensure the spotlight is on top
        },
        tooltip: {
          zIndex: 100002, // Ensure the tooltip is on top
        },
      }}
    />
  );

  return { TourComponent, setTourSection };
};

export default useTour;
