export const NETWORKS = {
  GOERLI: 5,
  SEPOLIA: 11155111,
  BNB: 56,
  ETH: 1,
};

export const ENV_TYPES = {
  DEV: "development",
  PRO: "production",
};

export const ROUTES = {
  ROOT: "/",
  AUTH: "/auth/:authType",
  LOGIN: "/auth/login",
  REGISTER: "/auth/register",
  FORGOT: "/auth/forgot-password",
  TWOFA: "/auth/2fa",
  LOCK: "/lock",
  HOME: "/home",
  TRADE: "/trade",
  BOTS: "/bots",
  ORDERS: "/orders",
  WALLET: "/wallet",
  ARBITRATION: "/arbitration",
  MARKETS: "/markets",
  NEWS: "/news",
  PROFILE: "/profile",
  ADMIN: "/admin",
  NOTIFICATIONS: "/notifications",
  TYC: "/terms-conditions",
  PPC: "/privacy-policy",
};

export const USER_TYPES = {
  CLIENT: "client",
  ADMIN: "admin",
};

export const USER_STATUS = {
  ACTIVATED: "activated",
  REGISTERED: "registered",
};

export const BASE_COIN = "USDT"; // could also be ETH or BTC

export const TOUR_STATUS = {
  SKIPPED: "skipped",
  FINISHED: "finished",
};

export const LANGUAGES = { ES: "es", EN: "en", ALL: ["es", "en"] };
