import { useState } from "react";

// react-router-dom components
import { useLocation, useNavigate } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";
import { changePassword } from "redux/actions/currentUser";
import { setInformativeMessage } from "redux/actions/informativeMessage";

// Constants
import { ROUTES } from "constants";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import IllustrationLayout from "layouts/Common/IllustrationLayout";

// Authentication layout components
import PasswordInputToggle from "examples/Inputs/PasswordInputToggle";
import ActionButton from "examples/Buttons/ActionButton";
import TextLink from "examples/Buttons/TextLink";

// Illustrations
import lock from "assets/images/illustrations/lock.png";
import canvioLogo from "assets/images/illustrations/canvio-logo.png";
import clientConfig from "utils/clientConfig";

// Translate
import { useTranslation } from "react-i18next";

export default function ResetPassword() {
  const { t } = useTranslation();
  const isCanvio = clientConfig.name === "canviodayone-notrading";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    password: "",
    passwordConfirmation: "",
    showPassword: false,
  });

  const handleChange = ({ target }) => {
    const { value, name } = target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateInputs = () => {
    const { password, passwordConfirmation } = formData;

    const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;

    if (!password || !passwordConfirmation) {
      return { success: false, message: "Fields are required." };
    }
    if (password !== passwordConfirmation) {
      return {
        success: false,
        message: "Passwords do not match. Please try again.",
      };
    }
    if (!passwordRegex.test(password)) {
      return {
        success: false,
        message:
          "Password must contain at least 8 characters, 1 uppercase, and 1 number",
      };
    }

    return { success: true, message: "" };
  };

  // Get code
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  // Get a specific query parameter
  const token = searchParams.get("token");

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const validation = validateInputs();
    if (validation.success) {
      (await dispatch(changePassword(token, formData.password))) &&
        navigate(ROUTES.LOGIN);
    } else {
      dispatch(setInformativeMessage({ ...validation }));
    }
    setLoading(false);
  };

  return (
    <IllustrationLayout
      title="Reset Password"
      description=""
      illustration={{
        image: isCanvio ? canvioLogo : lock,
        // title: '"Attention is the new currency"',
        // description: "The more effortless the writing looks, the more effort the writer actually put into the process.",
      }}
      embed={clientConfig.isDemo ? false : true}
    >
      <SoftBox component="form" role="form" onSubmit={handleSubmit}>
        <SoftBox mb={2} lineHeight={1.25}>
          <PasswordInputToggle onChange={handleChange} />
        </SoftBox>
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftInput
            type={formData.showPassword ? "text" : "password"}
            placeholder={t("Confirmed Password")}
            onChange={handleChange}
            name="passwordConfirmation"
            required
          />
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <ActionButton loading={loading} label="reset" />
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <TextLink />
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}
