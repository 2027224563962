export const userForm = {
  email: "Email",
  userName: "User Name",
  fullName: "Full Name",
  status: "Status",
  phone: "Phone",
  country: "Country",
  registerDate: "Register Date",
};

export const messageForm = {
  author: "Author",
  content: "Content",
  create_date: "Create Date",
};

export const videoForm = {
  title: "Title",
  url: "Youtube URL",
  create_date: "Create Date",
};
