import { useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";
import { resetActions } from "redux/actions/currentUser";
import { setInformativeMessage } from "redux/actions/informativeMessage";

// Constants
import { ROUTES } from "constants";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import IllustrationLayout from "layouts/Common/IllustrationLayout";

// Authentication layout components
import ActionButton from "examples/Buttons/ActionButton";
import TextLink from "examples/Buttons/TextLink";

// Illustrations
import lock from "assets/images/illustrations/lock.png";
import canvioLogo from "assets/images/illustrations/canvio-logo.png";
import clientConfig from "utils/clientConfig";

// Translate
import { useTranslation } from "react-i18next";

export default function ForgotPassword() {
  const { t } = useTranslation();
  const isCanvio = clientConfig.name === "canviodayone-notrading";
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    email: "",
  });

  const handleChange = ({ target }) => {
    const { value, name } = target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateInputs = () => {
    const { email } = formData;

    return !email ? false : true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const validation = validateInputs();
    if (validation) {
      (await dispatch(resetActions(formData.email))) && navigate(ROUTES.LOGIN);
    } else {
      dispatch(
        setInformativeMessage({ success: false, message: "Email is required." })
      );
    }
    setLoading(false);
  };

  return (
    <IllustrationLayout
      title="Reset Password"
      description="You will receive an e-mail in maximum 60 seconds"
      illustration={{
        image: isCanvio ? canvioLogo : lock,
        // title: '"Attention is the new currency"',
        // description: "The more effortless the writing looks, the more effort the writer actually put into the process.",
      }}
      embed={clientConfig.isDemo}
    >
      <SoftBox component="form" role="form" onSubmit={handleSubmit}>
        <SoftBox mb={2} lineHeight={1.25}>
          <SoftInput
            type="email"
            placeholder={t("Enter your e-mail")}
            onChange={handleChange}
            name="email"
            required
          />
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <ActionButton loading={loading} label="reset" />
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <TextLink />
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}
