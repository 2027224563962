import React from 'react';

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";

// Translate
import { useTranslation } from 'react-i18next';

export default function ProfitInput({ inputValue, setStrategy }) {
    const { t } = useTranslation();

    const handledChange = ({ target }) => {
        const { value } = target;
        setStrategy(prev => ({ ...prev, profit: value }));
    };

    return (
        <SoftBox id="profitInput" mb={3}>
            <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                >
                    {t('profit')}
                    <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                        color='error'
                    >
                        *
                    </SoftTypography>
                </SoftTypography>
            </SoftBox>
            <SoftInput
                className="inputs"
                name="profit"
                size="small"
                type="number"
                placeholder={t('%profit')}
                value={inputValue || ""}
                onChange={handledChange}
                required
            />
        </SoftBox>
    );
}
