// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

// Soft UI Dashboard React Base Styles
import colors from "assets/theme/base/colors";

// CONSTANTS
import { STATUS } from "constants/fetchStatus";

import icon from "assets/logos/favicon.png";

const { gradients } = colors;

export default function PlayList({
  videos,
  showPlayList,
  setShowPlayList,
  setCurrentVideo,
  toggleVideoPlaying,
}) {
  const { data, status } = videos;

  const handlePlay = () => {
    const index = 0;
    const { url } = data[index];
    setShowPlayList(!showPlayList);
    setCurrentVideo({ index, url });
  };

  return (
    <Card sx={{ height: "100%" }}>
      <SoftBox
        height="100%"
        bgColor="info"
        variant="gradient"
        sx={{ position: "relative" }}
      >
        <SoftBox
          width="100%"
          display="flex"
          justifyContent="end"
          alignItems="center"
          sx={{
            position: "absolute",
            px: "0.5vw",
            py: "1vh",
          }}
        >
          <Icon
            fontSize="large"
            sx={{
              color: colors.white.main,
              cursor: "pointer",
              zIndex: 999999,
              "&:hover": {
                transform: "scale(1.2)",
              },
            }}
            onClick={toggleVideoPlaying}
          >
            close
          </Icon>
        </SoftBox>
        <SoftBox p="1vw" display="flex" justifyContent="flex-start">
          <SoftBox
            p={3}
            height="100px"
            width="100px"
            bgColor="dark"
            variant="gradient"
            borderRadius="10px"
            mr="1vw"
            display="flex"
            justifyContent="flex-center"
          >
            <img src={icon} alt="CTrading Icon" width="100vw" />
          </SoftBox>
          <SoftBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <SoftTypography fontWeight="bold" color="white">
              Deep Focus
            </SoftTypography>
            <SoftTypography variant="overline" color="white">
              Crypto
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox px="1vw" display="flex" justifyContent="space-between">
          <SoftBox>
            <SoftBadge badgeContent="CTrading Play List" size="xs" container />
          </SoftBox>
          <SoftBox display="flex" alignItems="center" gap={3}>
            <Icon
              fontSize="large"
              sx={{
                color: colors.white.main,
                cursor: "pointer",
                "&:hover": {
                  transform: "scale(1.02)",
                },
              }}
              onClick={handlePlay}
            >
              play_circle
            </Icon>
          </SoftBox>
        </SoftBox>
        <Divider light />
        <SoftBox height="100%" px="1vw" py="1vw">
          <List
            sx={{
              width: "100%",
              height: "100%",
              position: "relative",
              overflow: "auto",
              maxHeight: "50%",
              "& ul": { padding: 0 },
              "&::-webkit-scrollbar": {
                width: "0.2em",
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: gradients.info.main,
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: colors.white.main,
              },
            }}
            subheader={<li />}
          >
            {status === STATUS.ERROR && (
              <SoftTypography
                sx={{ display: "inline" }}
                component="span"
                variant="button"
                color="white"
              >
                Oops, something went wrong!
              </SoftTypography>
            )}
            {data.map((video, index) => {
              const { title, duration, url, author } = video;
              return (
                <ListItem
                  key={`item-${index}`}
                  secondaryAction={
                    <SoftTypography
                      sx={{ display: "inline", fontSize: "15px" }}
                      variant="caption"
                      px="1vw"
                      color="white"
                    >
                      {duration}
                    </SoftTypography>
                  }
                  sx={{
                    cursor: "pointer",
                    borderRadius: "10px",
                    "&:hover": {
                      backgroundColor: gradients.info.main,
                    },
                  }}
                  onClick={() => {
                    setShowPlayList(!showPlayList);
                    setCurrentVideo({ index, url });
                    // setPlaying(!playing);
                  }}
                >
                  <ListItemText
                    primary={
                      <SoftBox
                        display="flex"
                        alignItems="center"
                        gap={2}
                        p="1vh"
                      >
                        <SoftBox>
                          <SoftTypography
                            sx={{ display: "inline" }}
                            component="span"
                            variant="caption"
                            color="white"
                          >
                            {index + 1}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                        >
                          <SoftTypography
                            sx={{
                              display: "inline-block",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "30ch",
                            }}
                            component="span"
                            variant="button"
                            color="white"
                          >
                            {title}
                          </SoftTypography>
                          <SoftTypography
                            sx={{
                              display: "inline-block",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              maxWidth: "30ch",
                            }}
                            component="span"
                            variant="caption"
                            color="white"
                          >
                            {author}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}
