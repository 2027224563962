// @mui material components
import Grid from "@mui/material/Grid";
// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { Card } from "@mui/material";
import { STATUS } from "constants/fetchStatus";
import CryptoIcon from "componentsCustom/CryptoIcon";
import Tooltip from "@mui/material/Tooltip";

// Translate
import { useTranslation } from 'react-i18next';

export default function PairBox({ pair, walletStatus }) {
    const { t } = useTranslation();
    const { free, locked, baseCoinValue, symbol, changePct24Hour, imageUrl } = pair
    return (
        <Grid item xs={6} md={4} lg={3} >
            <Card style={{ opacity: walletStatus === STATUS.LOADING ? 0.3 : 1 }} sx={{ height: "100%" }}>
                <SoftBox mx={2} my={1.5}>
                    <SoftBox style={{ width: "100%" }} display="inline-flex" justifyContent="space-between">
                        <CryptoIcon pair={symbol} imageUrl={imageUrl} />
                        <Tooltip title="24hs percentage diff" placement="top" sx={{ cursor: "pointer" }}>
                            <SoftTypography variant="p" fontSize="medium" fontWeight="bold" mt={0.8} color={changePct24Hour > 0 ? "success" : "error"}>
                                {changePct24Hour} %
                            </SoftTypography>
                        </Tooltip>
                    </SoftBox>
                    <SoftTypography className="amount-card">
                        {t('Free')}:
                        <span className="amount-crypto">
                            {` ${free}`}
                        </span>
                        <br></br>
                        {t('Locked')}:
                        <span className="amount-crypto">
                            &nbsp;{locked || 0}
                        </span>
                    </SoftTypography>
                    <SoftTypography className="amount-card">
                        $: <span className="amount-fiat">{baseCoinValue}</span>
                    </SoftTypography>
                </SoftBox>
            </Card>
        </Grid>
    )
}
