// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import PageLayout from "examples/LayoutContainers/PageLayout";

// Constants
import { ROUTES } from "constants";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

// Images
import error404 from "assets/images/illustrations/error-404.png";
import Footer from "layouts/Common/Footer";

function Error404() {
  const { d2, d4, d5, d6 } = typography;

  return (
    <PageLayout white>
      <SoftBox my={12}>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid item xs={11} sm={8} container alignItems="center">
            <Grid item xs={12} lg={6}>
              <SoftBox
                fontSize={{
                  xs: d6.fontSize,
                  sm: d5.fontSize,
                  md: d4.fontSize,
                  lg: d2.fontSize,
                }}
                lineHeight={1.2}
              >
                <SoftTypography
                  variant="inherit"
                  color="error"
                  textGradient
                  fontWeight="bold"
                >
                  Error 404
                </SoftTypography>
              </SoftBox>
              <SoftTypography
                variant="h2"
                color="dark"
                textGradient
                fontWeight="bold"
              >
                Erm. Page not found
              </SoftTypography>
              <SoftBox mt={1} mb={2}>
                <SoftTypography variant="body1" color="text">
                  We suggest you to go to the homepage while we solve this
                  issue.
                </SoftTypography>
              </SoftBox>
              <SoftBox mt={4} mb={2}>
                <SoftButton
                  component={Link}
                  to={ROUTES.ROOT}
                  variant="gradient"
                  color="info"
                >
                  go to homepage
                </SoftButton>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <SoftBox
                component="img"
                src={error404}
                alt="error-404"
                width="90%"
              />
            </Grid>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </PageLayout>
  );
}

export default Error404;
