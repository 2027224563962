import axios from "axios";
import { BACK_API } from "apiconfig";
import { store } from "index";

export async function fetchUsers() {
  const url = `${BACK_API}/users`;
  const params = {};
  const headers = {
    'token': store.getState().currentUser.data.jwtToken,
    'id': store.getState().currentUser.data.id
  };
  return await axios
    .get(url, { params, headers })
    .then(res => res.data);
}

export async function updateUserStatus(body) {
  const parsedBody = {
    id: body.id,
    status: body.status,
    email: body.email,
    userName: body.userName
  };
  const url = `${BACK_API}/user-status`;
  const headers = {
    'token': store.getState().currentUser.data.jwtToken,
    'id': store.getState().currentUser.data.id
  };
  return await axios
    .post(url, parsedBody, { headers })
    .then(res => res.data.rows);
}

export async function updateUserLanguage(id, language) {
  const parsedBody = { id, language };
  const url = `${BACK_API}/user-language`;
  const headers = {
    'token': store.getState().currentUser.data.jwtToken,
    'id': store.getState().currentUser.data.id
  };
  return await axios
    .post(url, parsedBody, { headers })
    .then(res => res.data);
}

export async function updateUserRequireTwoFA(twoFAStatus) {
  const parsedBody = { id: store.getState().currentUser.data.id, twoFAStatus };
  const url = `${BACK_API}/user-two-fa`;
  const headers = {
    'token': store.getState().currentUser.data.jwtToken,
    'id': store.getState().currentUser.data.id
  };
  return await axios
    .post(url, parsedBody, { headers })
    .then(res => res.data);
}

export async function updateUserRequireTours(toursStatus) {
  const parsedBody = { id: store.getState().currentUser.data.id, toursStatus };
  const url = `${BACK_API}/user-tours`;
  const headers = {
    'token': store.getState().currentUser.data.jwtToken,
    'id': store.getState().currentUser.data.id
  };
  return await axios
    .post(url, parsedBody, { headers })
    .then(res => res.data);
}

export async function updateUserPassword(body) {
  const url = `${BACK_API}/user-password`;
  const headers = {
    'token': store.getState().currentUser.data.jwtToken,
    'id': store.getState().currentUser.data.id
  };
  return await axios
    .post(url, body, { headers })
    .then(({ data }) => {
      return {
        isCompleted: true,
        success: true,
        error: false,
        message: "",
        data
      };
    })
    .catch(err => {
      return {
        isCompleted: true,
        success: false,
        error: true,
        message: err?.response?.data?.error,
        data: err
      };
    });
}

export async function deleteUser(userId) {
  const url = `${BACK_API}/delete-user`;
  const headers = {
    'token': store.getState().currentUser.data.jwtToken,
    'id': store.getState().currentUser.data.id
  };
  const body = { userId }
  return await axios
    .post(url, body, { headers })
    .then(res => res.data);
}

export async function updateUserInfo(newInfo) {
  const { id, jwtToken } = store.getState().currentUser.data;
  const url = `${BACK_API}/user/${id}`;
  const headers = {
    token: jwtToken,
    id
  };
  const body = {
    id,
    username: newInfo.username.trim(),
    fullName: newInfo.fullName.trim(),
    email: newInfo.email.trim(),
    country: newInfo.country,
    phone: newInfo.phone,
  };
  return await axios
    .put(url, body, { headers })
    .then(({ data }) => {
      const {
        id,
        username,
        full_name,
        email,
        country,
        phone,
      } = data;
      const userData = {
        jwtToken,
        id,
        username: username.trim(),
        fullName: full_name.trim(),
        email: email.trim(),
        country,
        phone,
      };
      return {
        isCompleted: true,
        success: true,
        error: false,
        message: "",
        data: userData
      }
    })
    .catch(error => {
      console.error(error);
      return {
        isCompleted: true,
        success: false,
        error: true,
        message: "",
      }
    });
}