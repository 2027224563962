// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import SoftBox from "components/SoftBox";

// Soft UI Dashboard PRO React base styles
import typography from "assets/theme/base/typography";

// Translate
import { useTranslation } from "react-i18next";

const TourButton = ({ variant, children, ...props }) => {
    const { t } = useTranslation();
    const basicStyles = {
        text: {
            color: "dark",
            fontWeight: "800",
            fontSize: "0.76rem",
            fontFamily: typography.fontFamily,
            textTransform: "uppercase",
            variant: "gradient",
            borderRadius: "6px",
            opacity: 1,
            px: 4,
            py: "0.7rem",
            bgColor: 'transparent',
            ...props
        },
        gradient: {
            color: "white",
            fontWeight: "800",
            fontSize: "0.76rem",
            fontFamily: typography.fontFamily,
            textTransform: "uppercase",
            variant: "gradient",
            borderRadius: "6px",
            shadow: "lg",
            opacity: 1,
            px: 4,
            py: "0.7rem",
            ...props
        }
    }

    return (
        <SoftBox
            {...basicStyles[variant]}
        >
            {t(children)}
        </SoftBox>
    )
}

TourButton.propTypes = {
    variant: PropTypes.oneOf(["text", "gradient"]),
};

export default TourButton