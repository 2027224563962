import { useState } from "react";

// react-router-dom components
import { useLocation, useNavigate } from "react-router-dom";

// Redux
import { useDispatch } from "react-redux";
import { registerActions } from "redux/actions/currentUser";
import { setInformativeMessage } from "redux/actions/informativeMessage";

// Routes
import { ROUTES } from "constants";

// Utils
import { registerForm } from "./schemas/form";

// Soft UI Dashboard React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";

// Authentication layout components
import IllustrationLayout from "layouts/Common/IllustrationLayout";
import PasswordInputToggle from "examples/Inputs/PasswordInputToggle";
import ActionButton from "examples/Buttons/ActionButton";
import TextLink from "examples/Buttons/TextLink";

// Illustrations
import rocket from "assets/images/illustrations/rocket-white.png";
import canvioLogo from "assets/images/illustrations/canvio-logo.png";
import clientConfig from "utils/clientConfig";
import useUserLanguage from "utils/useUserLanguage";

// Translate
import { useTranslation } from "react-i18next";

export default function Register() {
  const { t } = useTranslation();
  const isCanvio = clientConfig.name === "canviodayone-notrading";
  let { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const searchParams = new URLSearchParams(search);
  const referralCode = searchParams.get("ref");

  const userSystemLanguage = useUserLanguage();

  const [formData, setFormData] = useState({
    referralCode,
    username: "",
    password: "",
    email: "",
    nombreCompleto: "",
    phoneNumber: "",
    country: "",
    userSystemLanguage,
  });

  const handleChange = ({ target }) => {
    const { value, name } = target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const validateInputs = () => {
    const { username, password, email, nombreCompleto, country } = formData;

    if (!username || !password || !email || !nombreCompleto || !country) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const validation = validateInputs();
    if (validation) {
      (await dispatch(registerActions(formData))) && navigate(ROUTES.LOGIN);
    } else {
      dispatch(
        setInformativeMessage({
          success: false,
          message: "Input fields are required.",
        })
      );
    }
    setLoading(false);
  };

  return (
    <IllustrationLayout
      title="Join us today"
      description="Enter your information to register"
      illustration={{
        image: isCanvio ? canvioLogo : rocket,
        // title: '"Attention is the new currency"',
        // description: "The more effortless the writing looks, the more effort the writer actually put into the process.",
      }}
      embed={clientConfig.isDemo}
    >
      <SoftBox component="form" role="form" onSubmit={handleSubmit}>
        {registerForm.map((field) => (
          <SoftBox key={field.key} mb={1} lineHeight={1.25}>
            {field.name === "password" ? (
              <PasswordInputToggle {...field} onChange={handleChange} />
            ) : (
              <SoftInput
                {...field}
                placeholder={t(field.label)}
                onChange={handleChange}
              />
            )}
          </SoftBox>
        ))}
        <SoftBox mt={4} mb={1}>
          <ActionButton loading={loading} label="sign up" />
        </SoftBox>
        <SoftBox mt={3} textAlign="center">
          <TextLink />
        </SoftBox>
      </SoftBox>
    </IllustrationLayout>
  );
}
