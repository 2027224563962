import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// @mui material icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import StatusAnimations from "componentsCustom/StatusAnimations";

// Admin React components
import AdminButton from "../AdminButton";

// Utils
import { STATUS } from "constants/fetchStatus";
import { updateVideo } from "api/videos";
import { timestampToDateTime } from "utils/timestampToDateTime";

// Translate
import { useTranslation } from "react-i18next";
import { videoForm } from "../../schemas/form";

const parseYouTubeUrl = (url) => {
  const regex =
    /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match;
};

export default function EditVideo({
  videoData,
  handleCancel,
  handleRemove,
  handleRefresh,
}) {
  const { t } = useTranslation();
  const [isURLValid, setisURLValid] = useState(true);
  const { id: videoId, title, url, create_date } = videoData;
  const [formStatus, setFormStatus] = useState(STATUS.PENDING);
  const [tempVideoData, setTempVideoData] = useState({
    id: videoId,
    title,
    url,
    create_date,
  });

  const handleInputChange = ({ target }) => {
    const { name, value } = target;
    setTempVideoData({ ...tempVideoData, [name]: value });
    name === "url" && setisURLValid(parseYouTubeUrl(value));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (isURLValid) {
      setFormStatus(STATUS.LOADING);
      updateVideo(tempVideoData)
        .then(() => {
          handleRefresh();
          setFormStatus(STATUS.OK);
        })
        .catch((err) => {
          setFormStatus(STATUS.ERROR);
          throw Error(err);
        });
    }
  };

  return (
    <SoftBox my={3}>
      {formStatus === STATUS.PENDING && (
        <Card component="form" onSubmit={handleSubmit}>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <Stack spacing={1} direction="row" alignItems="center">
              <AdminButton onClick={handleCancel} size="medium">
                <ArrowBackIcon />
              </AdminButton>
              <SoftTypography variant="h5" fontWeight="medium">
                {t("Editing video:")} {videoId}
              </SoftTypography>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <AdminButton type="submit">{t("save")}</AdminButton>
              <AdminButton
                onClick={() => handleRemove(videoData)}
                color="error"
              >
                {t("remove")}
              </AdminButton>
            </Stack>
          </SoftBox>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <SoftBox width="100%">
              <SoftBox mb={2}>
                <SoftTypography variant="h5" fontWeight="medium">
                  {t("Video information")}
                </SoftTypography>
              </SoftBox>
              <SoftBox mt={1.625}>
                <Grid container spacing={3}>
                  {Object.entries(tempVideoData).map(
                    ([propName, propData], i) => {
                      if (propName !== "id") {
                        return (
                          <Grid key={i} item xs={12} sm={6}>
                            <SoftBox mb={1.5}>
                              <SoftBox
                                mb={1}
                                ml={0.5}
                                lineHeight={0}
                                display="inline-block"
                              >
                                <SoftTypography
                                  component="label"
                                  variant="caption"
                                  fontWeight="bold"
                                  textTransform="capitalize"
                                >
                                  {t(videoForm[propName])}
                                </SoftTypography>
                              </SoftBox>
                              <SoftInput
                                name={propName}
                                autoFocus={propName === "title"}
                                value={
                                  propName === "create_date"
                                    ? timestampToDateTime(propData)
                                    : propData
                                }
                                onChange={handleInputChange}
                                disabled={propName === "create_date"}
                                required
                                error={!isURLValid && propName === "url"}
                              />
                              {!isURLValid && propName === "url" && (
                                <SoftBox mt={0.75}>
                                  <SoftTypography
                                    component="div"
                                    variant="caption"
                                    color="error"
                                  >
                                    {t("Invalid YouTube URL")}
                                  </SoftTypography>
                                </SoftBox>
                              )}
                            </SoftBox>
                          </Grid>
                        );
                      }
                    }
                  )}
                </Grid>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      )}
      <StatusAnimations
        status={formStatus}
        setStatusFunction={setFormStatus}
        afterOKAnimationAction={handleCancel}
      />
    </SoftBox>
  );
}
