// Constants
import {
  SET_CURRENT_USER,
  SET_CURRENT_LANGUAGE_USER,
  LOGOUT,
} from "constants/actionTypes";
import { USER_TYPES } from "constants";

// API
import { registerUser, forgotPassword, resetPassword } from "api/auth";

// Actions
import { getKeys } from "./keys";
import { getUsers } from "./users";
import {
  getArbitrationActions,
  getArbitrationExchanges,
  getArbitrationOpportunities,
  getArbitrationPairs,
  getArbitrationStrategies,
} from "./arbitration";
import { getFavPairs } from "./favPairs";
import { setInformativeMessage } from "./informativeMessage";
import { getNotifications } from "./notifications";
import { updateUserLanguage } from "api/users";
import { getNews } from "./news";
import { STATUS } from "constants/fetchStatus";
import { SET_CURRENT_USER_STATUS } from "constants/actionTypes";
import { SET_SUBSCRIPTION_STATUS_USER } from "constants/actionTypes";
import { getVideos } from "./videos";
import { updateUserRequireTwoFA } from "api/users";
import { SET_REQUIRE_TWO_FA_USER } from "constants/actionTypes";
import { getMessages } from "./messages";
import { setInitialTourState } from "utils/tourState";
import { getPricing } from "./pricing";
import clientConfig from "utils/clientConfig";
import { updateUserRequireTours } from "api/users";
import { SET_REQUIRE_TOURS_USER } from "constants/actionTypes";

/***** Redux actions that end up in the store *****/
export const setCurrentUser = (user) => async (dispatch) => {
  try {
    dispatch({ type: SET_CURRENT_USER, payload: user });
  } catch (error) {
    console.log(error.message);
  }
};

export const registerActions = (formData) => async (dispatch) => {
  try {
    const response = await registerUser(formData);
    const { success, data } = response;
    dispatch(setInformativeMessage({ success, message: data }));
    return success;
  } catch (error) {
    console.log(error.message);
  }
};

export const loginActions = (data) => async (dispatch) => {
  try {
    const { role, tours } = data;
    dispatch(setCurrentUser(data));
    dispatch(getKeys());
    role === USER_TYPES.ADMIN && dispatch(getUsers());
    dispatch(getFavPairs());
    dispatch(getNotifications());
    dispatch(getNews());
    dispatch(getArbitrationActions());
    dispatch(getArbitrationExchanges());
    dispatch(getArbitrationOpportunities());
    dispatch(getArbitrationPairs());
    dispatch(getArbitrationStrategies());
    dispatch(getVideos());
    dispatch(getMessages());
    tours && setInitialTourState();
    clientConfig.usePremiumModel && dispatch(getPricing());
  } catch (error) {
    console.log(error.message);
  }
};

export const resetActions = (email) => async (dispatch) => {
  try {
    const response = await forgotPassword(email);
    const { success, data } = response;
    dispatch(setInformativeMessage({ success, message: data }));
    return success;
  } catch (error) {
    console.log(error.message);
  }
};

export const changePassword = (token, password) => async (dispatch) => {
  try {
    const response = await resetPassword(token, password);
    const { success, data } = response;
    dispatch(setInformativeMessage({ success, message: data }));
    return success;
  } catch (error) {
    console.log(error.message);
  }
};

export const changeLanguage = (id, language) => async (dispatch) => {
  try {
    const response = await updateUserLanguage(id, language);
    dispatch({ type: SET_CURRENT_LANGUAGE_USER, payload: response });
  } catch (error) {
    console.log(error.message);
  }
};

export const changeRequireTwoFA = (twoFAStatus) => async (dispatch) => {
  try {
    dispatch({ type: SET_CURRENT_USER_STATUS, payload: STATUS.LOADING });
    const response = await updateUserRequireTwoFA(twoFAStatus);
    dispatch({ type: SET_REQUIRE_TWO_FA_USER, payload: response });
    dispatch({ type: SET_CURRENT_USER_STATUS, payload: STATUS.OK });
  } catch (error) {
    console.log(error.message);
  }
};

export const changeTours = (toursStatus) => async (dispatch) => {
  try {
    dispatch({ type: SET_CURRENT_USER_STATUS, payload: STATUS.LOADING });
    const response = await updateUserRequireTours(toursStatus);
    dispatch({ type: SET_REQUIRE_TOURS_USER, payload: response });
    dispatch({ type: SET_CURRENT_USER_STATUS, payload: STATUS.OK });
  } catch (error) {
    console.log(error.message);
  }
};

export const updateSubscritionStatus = (status) => async (dispatch) => {
  try {
    dispatch({ type: SET_CURRENT_USER_STATUS, payload: STATUS.LOADING });
    dispatch({ type: SET_SUBSCRIPTION_STATUS_USER, payload: status });
    dispatch({ type: SET_CURRENT_USER_STATUS, payload: STATUS.OK });
  } catch (error) {
    console.log(error.message);
  }
};

export const logoutSesion = () => async (dispatch) => {
  dispatch({ type: LOGOUT, payload: null });
  sessionStorage.clear();
  localStorage.removeItem("reduxState");
};
