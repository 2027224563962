export const CLIENTS_CONFIG = [
    {
        name: "canviodayone-notrading",
        url: "https://canviodayone-notrading.tradingplatformcustom.com",
        email: "info@ctrading.io",
        emailName: "Canvio",
        domain: "tradingplatformcustom.com",
        useMatomo: false,
        matomoId: 0,
        matomoCookieDomain: "",
        useTrading: false,
        useArbitrage: false,
        useReferrals: false,
        needToApproveUsers: true,
        usePremiumModel: false,
        isDemo: false,
        useUnlock: false,
        unlockNetwork: "",
        unlockContracts: ""
    }
]
