import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// @mui material icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import Loader from "components/Loader";
import StatusAnimations from "componentsCustom/StatusAnimations";

// Admin React components
import AdminButton from "../AdminButton";

// Utils
import { updateUserStatus } from "api/users";
import { fetchReferralEarnings } from "api/referrals";
import { timestampToDateTime } from "utils/timestampToDateTime";
import { STATUS } from "constants/fetchStatus";
import { USER_STATUS } from "constants";
import { userForm } from "../../schemas/form";

// Translate
import { useTranslation } from "react-i18next";

export default function EditUser({
  userData,
  handleCancel,
  handleRemove,
  handleRefresh,
}) {
  const { t } = useTranslation();
  const { id: userId, email, userName } = userData;
  const [formStatus, setFormStatus] = useState(STATUS.PENDING);
  const [userEarnings, setUserEarnings] = useState();
  const [tempUserData, setTempUserData] = useState({
    id: userId,
    email,
    userName,
  });

  const options = [
    { label: t("Registered"), value: USER_STATUS.REGISTERED },
    { label: t("Activated"), value: USER_STATUS.ACTIVATED },
  ];

  const handleInputChange = ({ value }) => {
    setTempUserData({ ...tempUserData, status: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormStatus(STATUS.LOADING);
    updateUserStatus(tempUserData)
      .then(() => {
        handleRefresh();
        setFormStatus(STATUS.OK);
      })
      .catch((err) => {
        setFormStatus(STATUS.ERROR);
        throw Error(err);
      });
  };

  useEffect(() => {
    fetchReferralEarnings(userId)
      .then((res) => setUserEarnings(res))
      .catch((res) => console.log(res));
  }, []);

  return (
    <SoftBox my={3}>
      {formStatus === STATUS.PENDING && (
        <Card component="form" onSubmit={handleSubmit}>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <Stack spacing={1} direction="row" alignItems="center">
              <AdminButton onClick={handleCancel} size="medium">
                <ArrowBackIcon />
              </AdminButton>
              <SoftTypography variant="h5" fontWeight="medium">
                {t("Editing user: ")}
                {email}
              </SoftTypography>
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center">
              <AdminButton type="submit">{t("save")}</AdminButton>
              <AdminButton onClick={() => handleRemove(userData)} color="error">
                {t("remove")}
              </AdminButton>
            </Stack>
          </SoftBox>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <SoftBox width="100%">
              <SoftBox mb={2}>
                <SoftTypography variant="h5" fontWeight="medium">
                  {t("Personal information")}
                </SoftTypography>
              </SoftBox>
              <SoftBox mt={1.625}>
                <Grid container spacing={3}>
                  {Object.entries(userData).map(([propName, propData], i) => {
                    if (propName !== "id") {
                      return (
                        <Grid key={i} item xs={12} sm={6}>
                          <SoftBox mb={1.5}>
                            <SoftBox
                              mb={1}
                              ml={0.5}
                              lineHeight={0}
                              display="inline-block"
                            >
                              <SoftTypography
                                component="label"
                                variant="caption"
                                fontWeight="bold"
                                textTransform="capitalize"
                              >
                                {t(userForm[propName])}
                              </SoftTypography>
                            </SoftBox>
                            {propName === "status" ? (
                              <SoftSelect
                                options={options}
                                defaultValue={[...options].find(
                                  (option) => option.value === propData
                                )}
                                menuPosition="fixed"
                                onChange={handleInputChange}
                                autoFocus
                              />
                            ) : (
                              <SoftInput
                                value={
                                  propName === "registerDate"
                                    ? timestampToDateTime(propData)
                                    : propData
                                }
                                disabled
                              />
                            )}
                          </SoftBox>
                        </Grid>
                      );
                    }
                  })}
                  <Grid item xs={12} sm={6}>
                    {userEarnings ? (
                      <SoftBox>
                        <SoftBox mb={1.5}>
                          <SoftBox
                            mb={1}
                            ml={0.5}
                            lineHeight={0}
                            display="inline-block"
                          >
                            <SoftTypography
                              component="label"
                              variant="body2"
                              fontWeight="bold"
                              textTransform="capitalize"
                            >
                              {t("User earnings: ")}
                              {userEarnings.totalEarnings} eu
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                        <SoftBox
                          mb={1}
                          ml={0.5}
                          lineHeight={0}
                          display="inline-block"
                        >
                          <SoftTypography
                            component="label"
                            variant="body2"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {t("Paid to user: work in progress")}
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    ) : (
                      <Loader />
                    )}
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Card>
      )}
      <StatusAnimations
        status={formStatus}
        setStatusFunction={setFormStatus}
        afterOKAnimationAction={handleCancel}
      />
    </SoftBox>
  );
}
